import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=47995152&scoped=true&"
import script from "./Contact.vue?vue&type=script&lang=js&"
export * from "./Contact.vue?vue&type=script&lang=js&"
import style0 from "./Contact.vue?vue&type=style&index=0&id=47995152&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47995152",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContactForm: require('/codebuild/output/src733520475/src/aisle-rocket-2022/components/Contact/ContactForm.vue').default})
